<template>
  <v-container fluid>
    <SubTitleBar :title="'สร้างหน่วยบริการ'" :goback="'sitesettings'"/>
     <v-row class="pl-5 pr-10 pt-1">
          <v-col cols="12">
              <v-card>
                  <v-card-text>
                     <SiteInfoCard :standaloneMode="true"/>
                  </v-card-text>
              </v-card>
          </v-col>
     </v-row>
  </v-container>
</template>

<script>
// import { postSite } from '@/api/'
import SubTitleBar from '@/components/common/SubTitleBar'
import SiteInfoCard from '@/components/site/SiteInfoCard'
// import SiteMemberCard from '@/components/site/SiteMemberCard'
// import SiteOperatorCard from '@/components/site/SiteOperatorCard'
// import SiteConnectionCard from '@/components/site/SiteConnectionCard'
// import SiteTargetCard from '@/components/site/SiteTargetCard'
// import ConfirmCard from '@/components/common/ConfirmCard'
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['siteId'],
  components: {
    SiteInfoCard,
    SubTitleBar
  },
  data () {
    return {
      loading: false,
      confirmDialog: false,
      submitData: false,
      siteData: {},
      siteUser: [],
      tab: null,
      items: [
        { tab: 'ข้อมูลทั่วไป', visible: true },
        { tab: 'สมาชิก', visible: false },
        { tab: 'เจ้าหน้าที่คัดกรอง', visible: false },
        { tab: 'การเชื่อมต่อ', visible: false },
        { tab: 'เป้าหมาย', visible: false }
      ]
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      if (this.$auth.role) {
        this.currentSite = {}
        this.loading = false
      }
    }
  },
  computed: {
    ...mapFields(['currentSite', 'postStatus'])
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeRouteLeave (to, from, next) {
    if (!this.postStatus.code) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>

<style>

</style>
