<template>
  <v-container fluid class="pl-5 pr-10">
    <SubTitleBar :title="'แก้ไขหน่วยบริการ'" :goback="'sitesettings'">
      <template slot="action">
          สถานะหน่วยบริการ <Select :loading="loading" dense :items="activeItems" item-text="text" item-value="value" v-model="currentSite.active">
           <template v-slot:selection="{ item }">
              <li style="list-style-type: circle;width: -1em;" class="success--text" v-if="item.value"><span class="success--text">พร้อมใช้</span></li>
              <li style="list-style-type: circle;width: -1em;" class="error--text" v-else><span class="error--text">ระงับใช้</span></li>
           </template>
           <template v-slot:item="{ item }">
               <li style="list-style-type: circle;width: -1em;" class="success--text" v-if="item.value"><span class="success--text">พร้อมใช้</span></li>
              <li style="list-style-type: circle;width: -1em;" class="error--text" v-else><span class="error--text">ระงับใช้</span></li>
             </template>
             </Select>
      </template>
    </SubTitleBar>
     <v-row>
          <v-col cols="12">
              <v-card>
                  <v-card-text>
                     <v-tabs
      v-model="tab"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
        :disabled="!item.visible"
        :to="item.to"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
        <v-divider class="pb-5"/>
 <router-view></router-view>
                  </v-card-text>
              </v-card>
          </v-col>
     </v-row>
  </v-container>
</template>

<script>
import { getSites, patchSite } from '@/api/'
import SubTitleBar from '@/components/common/SubTitleBar'
import Select from '@/components/common/Select'
import { mapFields } from 'vuex-map-fields'
// import SiteInfoCard from '@/components/site/SiteInfoCard'
// import SiteMemberCard from '@/components/site/SiteMemberCard'
// import SiteOperatorCard from '@/components/site/SiteOperatorCard'
// import SiteConnectionCard from '@/components/site/SiteConnectionCard'
// import SiteTargetCard from '@/components/site/SiteTargetCard'

export default {
  props: ['siteId'],
  components: {
    // SiteInfoCard,
    // SiteMemberCard,
    // SiteOperatorCard,
    // SiteConnectionCard,
    // SiteTargetCard,
    Select,
    SubTitleBar
  },
  data () {
    return {
      loading: false,
      siteData: {},
      siteUser: [],
      tab: null,
      items: [
        { tab: 'ข้อมูลทั่วไป', visible: true, to: { name: 'siteinfo', params: this.$route.params.id } },
        { tab: 'สมาชิก', visible: true, to: { name: 'sitemember' } },
        { tab: 'เจ้าหน้าที่คัดกรอง', visible: true, to: { name: 'siteoperator' } },
        { tab: 'กลุ่ม', visible: true, to: { name: 'group' } },
        { tab: 'เป้าหมาย', visible: true, to: { name: 'sitetarget' } },
        { tab: 'ประเภทแบบฟอร์ม', visible: true, to: { name: 'siteform' } },
        { tab: 'เนื้อหากฏหมายและความยินยอม', visible: true, to: { name: 'sitecontent' } },
        { tab: 'การเชื่อมต่อ', visible: true, to: { name: 'siteconnection' } }
      ],
      activeItems: [
        { text: 'พร้อมใช้', value: true, color: 'success' },
        { text: 'ระงับใช้', value: false, color: 'error' }
      ]
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      if (this.$auth.role) {
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        getSites({
          tenantId: tenant.tenantId,
          siteId: this.siteId,
          siteName: ''
        }, message => {
          if (message.data.code === 1) {
            this.currentSite = message.data.result.items[0]
          } else {
            this.currentSite = {}
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      }
    }
  },
  computed: {
    ...mapFields(['currentSite'])
  },
  watch: {
    'currentSite.active': function (val) {
      this.loading = true
      const data = this.currentSite
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      patchSite({ id: this.currentSite.id, etag: this.currentSite._etag, tenantId: tenant.tenantId, siteId: this.currentSite.siteId }, data, message => {
        if (message.data.code === 1) {
          this.currentSite = message.data.result
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeRouteLeave (to, from, next) {
    const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
}
</script>

<style>

</style>
