<template>
  <v-container fluid>
    <TitleBar :title="'จัดการหน่วยบริการ'" :subtitle="items.length + ' หน่วยบริการ'">
      <template v-slot:action>
          <v-btn v-if="$auth.isTenantAdmin || $auth.role.isSystemAdmin" large color="primary" @click="siteCreate"><v-icon>mdi-plus</v-icon><span class="text-title-2">สร้างหน่วยบริการ</span></v-btn>
      </template>
    </TitleBar>
    <FilterBar>
      <template v-slot:left>
        <v-col cols="12" md="4" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,ผู้สร้าง,อื่นๆ"/>
        </v-col>
        <v-col cols="12" md="2" >
 <Select :title="'สถานะ'" :placeholder="'โปรดเลือก'" v-model="status" :items="statusItems" item-text="text" item-value="value"></Select>
        </v-col>
      </template>
      <template v-slot:right>
      </template>
    </FilterBar>
    <DataTable :headers="headers" :loading="loading" :search="search" :items="dataItem">
       <template v-slot:item.action="{ item }">
         <v-btn icon @click="gotoEdit(item)" :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
            <v-icon>mdi-pencil</v-icon>
         </v-btn>
       </template>
       <template v-slot:item.active="{ item }">
                <li style="list-style-type: circle;width: -1em;" class="success--text" v-if="item.active"><span class="success--text">พร้อมใช้</span></li>
       <li style="list-style-type: circle;width: -1em;" class="error--text" v-else><span class="error--text">ระงับใช้</span></li>
       </template>
    </DataTable>
    <v-snackbar
        v-model="postStatus.code"
        color="natural darken-4"
      >
      <v-avatar size="35">
      <v-img width="35" :src="require('@/assets/circlesuccess.svg')"/>
      </v-avatar>
        {{postStatus.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="postStatus.code = false"
          >
            <v-icon>
              mdi-close
           </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import TextField from '@/components/common/TextField'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import Select from '@/components/common/Select'
import { getSites } from '@/api/'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    TitleBar,
    TextField,
    FilterBar,
    DataTable,
    Select
  },
  data () {
    return {
      headers: [
        { text: 'เลขที่หน่วยบริการ', value: 'healthProviderCode9', class: 'primary lighten-5' },
        { text: 'ชื่อหน่วยบริการ', value: 'siteName', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'active', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      headers2: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'type', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5' },
        { text: 'วันที่สร้าง', value: 'createdate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createByUserName', class: 'primary lighten-5' },
        { text: 'จำนวนคนตอบ', value: 'total', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'active', class: 'primary lighten-5' },
        { text: 'แก้ไข', value: 'action', class: 'primary lighten-5' }
      ],
      loading: false,
      items: [],
      search: null,
      statusItems: [
        { text: 'ทั้งหมด', value: [true, false] },
        { text: 'พร้อมใช้', value: [true] },
        { text: 'ระงับใช้', value: [false] }
      ],
      status: [true]
    }
  },
  computed: {
    ...mapFields(['postStatus']),
    dataItem () {
      return this.items.filter(item => this.status.includes(item.active))
    }
  },
  methods: {
    gotoEdit (item) {
      this.postStatus = {}
      this.$router.push({ name: 'siteinfo', params: { siteId: item.siteId } })
    },
    siteCreate () {
      this.postStatus = {}
      this.$router.push({ name: 'sitecreate' })
    },
    fetchData () {
      this.loading = true
      if (this.$auth.role) {
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        getSites({
          tenantId: tenant.tenantId,
          siteId: ((this.$auth.isTenantAdmin) ? '' : site.siteId),
          siteName: ''
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              this.items = message.data.result.items
            } else {
              this.items = []
            }
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }

}
</script>
